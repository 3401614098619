import React from "react";

import 'style/components/Loader.scss';

function Loader({ label, size }) {
  return (
    <div
      className={`loader-container loader-container-${size ? size : "small"}`}
    >
      <div className="loader-spinner" />
      {label && <div className="loader-label">{label}</div>}
    </div>
  );
}

export default Loader;
