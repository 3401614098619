/**
 * A basic logger to encapsulate the console.log.
 * It log stuff only on DEBUG mode.
 */

const isLoggerEnabled = process.env.NODE_ENV !== 'production';

class Logger {
  static log(...messages) {
    if (isLoggerEnabled) {
      console.log(...messages);
    }
  }

  static error(...messages) {
    if (isLoggerEnabled) {
      console.error('[ERROR]', ...messages);
    }
  }

  static warning(...messages) {
    if (isLoggerEnabled) {
      console.warn('[WARN]', ...messages);
    }
  }

  static debug(...messages) {
    if (isLoggerEnabled) {
      console.debug('[DEBUG]', ...messages);
    }
  }
}

export default Logger;
