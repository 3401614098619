import React from "react";

import { Button } from "antd";

function isSubmitEnabled(form) {
  return form.isFieldsTouched() && !hasFieldsError(form);
}
function hasFieldsError(form) {
  const errors = form.getFieldsError();
  return Object.keys(errors).some(k => !!errors[k]);
}

/**
 * This component wrap the AntD Button component to 
 * add some contextual Form behaviours.
 * 
 * It's disabled if no fields have been touched in the form
 * or if there is an error in one of the fields.
 * @param {*} props 
 */
function SubmitButton({ form, children, ...props }) {
  return (
    <Button htmlType="submit" disabled={!isSubmitEnabled(form)} {...props}>
      {children}
    </Button>
  );
}

export default SubmitButton;
