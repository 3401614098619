import React from "react";

import { Form, Icon } from "antd";

import InputFormItem from "components/form/InputFormItem";
import SubmitButton from "components/form/SubmitButton";

import Locale from "locale/LocaleFactory";

class LoginForm extends React.Component {
  handleSubmit = e => {
    const { form, onLogin } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err && this.props.onLogin) {
        const { userName, password } = values;
        onLogin(userName, password, false);
      }
    });
  };

  render() {
    const { form, loading } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <InputFormItem
          id="userName"
          addonBefore={<Icon type="user" />}
          placeholder={Locale.trans("login.username")}
          required
          hasFeedback
          form={form}
        />
        <InputFormItem
          id="password"
          type="password"
          required
          addonBefore={<Icon type="lock" />}
          placeholder={Locale.trans("user.password")}
          form={form}
          hasFeedback
        />
        <SubmitButton type="primary" block loading={loading} form={form}>
          {Locale.trans("login.logInBtn")}
        </SubmitButton>
      </Form>
    );
  }
}

export default Form.create({
  onFieldsChange(props, changedFields) {
    if (props.onChange) {
      props.onChange(changedFields);
    }
  },
  mapPropsToFields(props) {
    const { fields } = props;
    if (!fields) {
      return {};
    }
    const kFields = Object.keys(fields);
    const map = {};
    for (let i = 0; i < kFields.length; i++) {
      const k = kFields[i];
      map[k] = Form.createFormField({
        ...fields[k],
        value: fields[k].value
      });
    }
    return map;
  }
})(LoginForm);
