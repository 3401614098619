// Apollo
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { toIdValue } from "apollo-utilities";
import { ApolloLink, from } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { logout } from 'contexts/AuthContext';

import Parameters from "Parameters";

let AUTH_TOKEN = null;

const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      // See https://www.apollographql.com/docs/react/recipes/performance.html#cache-redirects
      // The idea is to link the "user" query with the "User" entity available in the cache.
      user: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: "User", id })),
      provider: (_, { id }) =>
        toIdValue(
          cache.config.dataIdFromObject({ __typename: "Provider", id })
        ),
      land: (_, { id }) =>
        toIdValue(cache.config.dataIdFromObject({ __typename: "Land", id })),
      prospect: (_, { id }) =>
          toIdValue(cache.config.dataIdFromObject({ __typename: "Prospect", id})),
    },
  },
});

const uploadLink = new createUploadLink({
    uri: Parameters.apiUrl
});

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
      headers: {
        "X-Auth-Token": AUTH_TOKEN ? AUTH_TOKEN : null,
      },
    });

    return forward(operation);
});

const loggOutAfterware = onError(({ graphQLErrors, response }) => {
    if (! graphQLErrors) {
        return;
    }
    const error = graphQLErrors.filter(error => error.unauthenticated)[0];

    if (error) {
        logout();
    }
});

const client = new ApolloClient({
  link: from([authMiddleware, loggOutAfterware, uploadLink]),
  cache
});

function updateAuthToken(authToken) {
  AUTH_TOKEN = authToken;
}

export { client, updateAuthToken };
