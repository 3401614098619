import React from "react";
import FormItemBase from "components/form/FormItemBase";
import { Form, Input } from "antd";

export default class InputFormItem extends FormItemBase {
  render() {
    const { id, initialValue, readOnly } = this.props;
    // Form.Item extra props
    const { label, labelCol, wrapperCol, extra } = this.props;
    // Input props
    const {
      type,
      placeholder,
      disabled,
      prefix,
      suffix,
      addonBefore,
      addonAfter,
      maxLength,
      onChange,
      grouped,
      className,
      name,
      rules,
    } = this.props;

    if (grouped) {
      let size = "50%";
      if (grouped !== true) {
        size = grouped;
      }
      return this.getFieldDecorator(id, {
        initialValue,
        rules: this.getRules(
          [
            {
              max: 255,
            },
          ].concat(rules || [])
        ),
      })(
        <Input
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          type={type}
          prefix={prefix}
          suffix={suffix}
          className={className}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          maxLength={maxLength}
          onChange={onChange}
          ref={n => (this.input = n)}
          style={{ width: size }}
        />
      );
    }

    return (
      <Form.Item
        validateStatus={this.getValidateStatus(id)}
        help={this.getHelp(id, label || name)}
        hasFeedback
        label={label}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        extra={extra}
      >
        {this.getFieldDecorator(id, {
          initialValue,
          rules: this.getRules(
            [
              {
                max: 255,
              },
            ].concat(rules || [])
          ),
        })(
          <Input
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly}
            type={type}
            prefix={prefix}
            suffix={suffix}
            className={className}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            maxLength={maxLength}
            onChange={onChange}
            ref={n => (this.input = n)}
          />
        )}
      </Form.Item>
    );
  }
}
