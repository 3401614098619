import React, {useState} from 'react';

import uuid from 'uuid/v4'

class Modal {
  _handler;
  _id;
  _opened;
  _element;
  _props;

  constructor(id, handler, object) {
    this._handler = handler;
    this._id = id;
    this._opened = true;
    this._element = object.element;
    this._props = object.props;
  }


  close = () => {
    this._opened = false;
    this._handler.close(this._id);
  };

  get id() {
    return this._id;
  }


  get element() {
    return this._element;
  }

  get props() {
    const propsOnCancel = this._props.onCancel;
    return {
      ...this._props,
      visible: this._opened,
      onCancel: () => {
        propsOnCancel && propsOnCancel();
        this.close();
      },
    };
  }
}

const ModalHandler = class {
  _updateModalsToRender;

  constructor(updateModalsToRender) {
    this._updateModalsToRender = updateModalsToRender;
    this._modals = {};
  }


  open = (object) => {
    let id;

    let count = 0;

    do {
      id = uuid();
      count++;
    } while (this._modals[id] !== undefined && count < 100);
    this._modals[id] = new Modal(id, this, object);
    this._updateModalsToRender(Object.values(this._modals));
    return this._modals[id];
  };

  close = (id) => {
    delete this._modals[id];
    this._updateModalsToRender(Object.values(this._modals));
  };
  //TODO cache et suppression
};

let modalHandler;

const ModalElement = React.memo(() => {
  const [modalsToRender, setModalsToRender] = useState([]);
  if (!modalHandler) {
    modalHandler = new ModalHandler(setModalsToRender);
  }

  return (
    <div className="modal">
      {modalsToRender.map((modal, index) => {
        const ModalElement = modal.element;

        return (
          <ModalElement
            key={index}
            modalId={index}
            {...modal.props}
          />
        )
      })}
    </div>
  )
});

const open = (ModalElement, props = {}, options = {}) => {
  return modalHandler.open({
    element: ModalElement,
    props: props,
    options: options,
  });
};
export default ModalElement;
export {
  open,
}
