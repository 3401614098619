import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

function withDragDrop(component) {
  return DragDropContext(HTML5Backend)(component);
}

const DragDropProvider = withDragDrop(({ children }) => children);

export { DragDropProvider, withDragDrop };
