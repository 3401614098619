import React from "react";

import { LocaleProvider } from "antd";
import ModalHandler from "components/ModalHandler";

import frFR from "antd/lib/locale-provider/fr_FR";

import "antd/dist/antd.css";
import "style/App.scss";

// Router
import ProtectedRoute from "components/ProtectedRoute";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "contexts/AuthContext";

// Views
import Layout from "views/Layout";
import Login from "views/Login";

// Apollo
import { client } from "services/ApolloClient";
import { ApolloProvider } from "react-apollo";

// React D&D
import { DragDropProvider } from "contexts/DragDropContext";

function App() {
  return (
    <LocaleProvider locale={frFR}>
      <ApolloProvider client={client}>
        <AuthProvider>
          <DragDropProvider>
            <Router>
              <Switch>
                <Route exact path="/login" component={Login} />
                <ProtectedRoute path="/" component={Layout} />
              </Switch>
            </Router>
            <ModalHandler />
          </DragDropProvider>
        </AuthProvider>
      </ApolloProvider>
    </LocaleProvider>
  );
}

export default App;
