import React, { lazy, Suspense } from "react";
import { Link, Route, Switch } from "react-router-dom";
import ScrollArea from "react-scrollbar";

import { Avatar, Button, Icon, Layout, Menu, Popover } from "antd";

import Locale from "locale/LocaleFactory";

import { AuthConsumer } from "contexts/AuthContext";

import logo from "images/logo.png";
import logoIcon from "images/logo-icon.png";

import "style/views/Layout.scss";
// suppress prop-types warning on Route component when using with React.lazy
// until react-router-dom@4.4.0 or higher version released
/* eslint-disable react/forbid-foreign-prop-types */
import PropTypes from "prop-types";
import LoaderOverlay from "components/LoaderOverlay";

Route.propTypes.component = PropTypes.oneOfType([
  Route.propTypes.component,
  PropTypes.object
]);
/* eslint-enable react/forbid-foreign-prop-types */

const BillDashboard = lazy(() => import("views/Bill/BillDashboard"));
const Prospects = lazy(() => import("views/Prospect/Prospects"));
const Configuration = lazy(() => import("views/Configuration/Configuration"));
const Projects = lazy(() => import("views/Project/Projects"));
const Lands = lazy(() => import("views/Land/Lands"));
const Home = lazy(() => import("views/Home"));

const { Header, Content, Sider } = Layout;

class AppLayout extends React.Component {
  state = {
    menuCollapsed: localStorage.getItem("sidebar-collapsed") === "true"
  };

  handleMenuClick = e => {
    if (e.key !== this.props.location.pathname) {
      this.props.history.push(e.key);
    }
  };

  onCollapse = collapsed => {
    this.setState({
      menuCollapsed: collapsed
    });
    localStorage.setItem("sidebar-collapsed", collapsed.toString());
  };

  render() {
    const { pathname } = this.props.location;
    let selectedMenu = pathname;
    if (selectedMenu.startsWith("/projects")) {
      selectedMenu = "/projects";
    }

    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={this.state.menuCollapsed}
          onCollapse={this.onCollapse}
        >
          <ScrollArea horizontal={false}>
            <Link to="/">
              <div className="logo">
                <img className="logo-icon" alt="Accueil" src={logoIcon} />
                <img className="logo-text" alt="Accueil" src={logo} />
              </div>
            </Link>

            <Menu
              theme="dark"
              onClick={this.handleMenuClick}
              selectedKeys={[selectedMenu]}
              mode="inline"
            >
              <Menu.Item key="/">
                <Icon type="home" />
                <span>{Locale.trans("menu.home")}</span>
              </Menu.Item>
              <Menu.Item key="/lands">
                <Icon type="setting" />
                <span>{Locale.trans("menu.lands")}</span>
              </Menu.Item>
              <Menu.Item key="/prospects">
                <Icon type="user" />
                <span>{Locale.trans("menu.prospects")}</span>
              </Menu.Item>
              <Menu.Item key="/projects">
                <Icon type="folder" />
                <span>{Locale.trans("menu.projects")}</span>
              </Menu.Item>
              <Menu.Item key="/bill">
                <Icon type="euro" />
                <span>{Locale.trans("menu.bill")}</span>
              </Menu.Item>
              <Menu.Item key="/configuration">
                <Icon type="setting" />
                <span>{Locale.trans("menu.configuration")}</span>
              </Menu.Item>
            </Menu>
          </ScrollArea>
        </Sider>
        <Layout>
          <AuthConsumer>
            {({ authToken, updateAuthToken }) => (
              <Header
                style={{
                  paddingLeft: this.state.menuCollapsed ? "130px" : "250px"
                }}
              >
                <div className="welcome-message">
                  {Locale.trans("header.welcomeMessage", {
                    name: `${
                      authToken.user.firstName
                    } ${authToken.user.lastName.toUpperCase()}`
                  })}
                </div>
                <Popover
                  overlayClassName="layout-user-menu"
                  content={
                    <React.Fragment>
                      <Button
                        htmlType="button"
                        icon="logout"
                        onClick={() => updateAuthToken(null)}
                      >
                        {Locale.trans("login.logOutBtn")}
                      </Button>
                    </React.Fragment>
                  }
                  title={null}
                  placement="bottomRight"
                >
                  <Avatar size="large" className="profile-btn">
                    {authToken.user.firstName.charAt(0).toUpperCase()}
                    {authToken.user.lastName.charAt(0).toUpperCase()}
                  </Avatar>
                </Popover>
              </Header>
            )}
          </AuthConsumer>
          <Content
            style={{ marginLeft: this.state.menuCollapsed ? "80px" : "200px" }}
          >
            <Suspense fallback={<LoaderOverlay size="large" loading={true} />}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/lands" component={Lands} />
                <Route exact path="/prospects" component={Prospects} />
                <Route exact path="/projects" component={Projects} />
                <Route exact path="/projects/:id" component={Projects} />
                <Route exact path="/bill" component={BillDashboard} />
                <Route exact path="/configuration" component={Configuration} />
              </Switch>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default AppLayout;
