// @flow

import { notification } from 'antd';

class ToastService {
    createToast(message, description, toastType) {
        notification[toastType]({
            message,
            description,
        });
    }

    error(message, description) {
        this.createToast(message, description, 'error');
    }
    success(message, description) {
        this.createToast(message, description, 'success');
    }
    
    createToastError(message, description) {
        this.createToast(message, description, 'error');
    }
    createToastSuccess(message, description) {
        this.createToast(message, description, 'success');
    }
}

export default new ToastService();
