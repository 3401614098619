export default {
  // GinkGo
  Trans: {
    // Dates
    date: {
      apiFormat: "YYYY-MM-DD",
      displayFormat: "DD/MM/YYYY",
      displayFormatHours: "DD/MM/YYYY HH:mm",
      creation: 'Date de création',
    },
    time: {
      apiFormat: "HHmm",
    },

    // Generic words
    search: "Rechercher",
    save: "Enregistrer",
    edit: "Modifier",
    delete: "Supprimer",
    download: "Télécharger",
    cancel: "Annuler",
    next: "Suivant",
    clone: "Dupliquer",
    add: "Ajouter",
    save_next: "Enregistrer et suivant",
    reset: "Réinitialiser",
    refresh: "Actualiser",
    price: "Prix",
    loading: "Chargement...",
    min: "Min",
    max: "Max",
    m2: "m²",
    ml: 'ml',
    m: 'm',
    u: 'U',
    yes: "Oui",
    no: "Non",
    computed: "Calculé",
    update: "Modifier",
    ok: "OK",
    nok: "NOK",
    details: "Détails",
    today: "Aujourd'hui",
    tomorrow: "Demain",
    yesterday: "Hier",
    last: "dernier",
    area: "Surface",
    height: 'Hauteur',
    generate: 'Générer',
    generic: {
      length: 'Longueur',
      perimeter: 'Périmètre',
      days: 'Jours',
    },
    retry: 'Réessayer',
    estimate: 'Estimation',
    total: "Total",
    wall: 'Mur',
    part: 'Partie',

    logged_out: "Vous avez été déconnecté.",
    logged_out_description:
      "Vous êtes resté trop longtemps inactif, et avez été déconnecté par sécurité. Veuillez vous reconnecter.",

    // Errors
    error: {
      title: 'Erreur',
      generic: "Une erreur est survenue",
      required: "Ce champ est obligatoire",
      "select.notFoundContent": "Aucun",
      network: {
        description:
          "Une erreur sur le réseau a été rencontrée. Êtes-vous sûr d'être connecté à Internet?",
      },
      api: "Une erreur a été rencontrée dans l'application.",
      fileType:
        "Le type de fichier n'est pas bon. Veuillez fournir un fichier de type __fileType__",
    },

    menu: {
      home: "Accueil",
      lands: "Terrains",
      prospects: "Prospects",
      projects: "Projets",
      bill: "Facturation",
      configuration: "Configuration",
    },

    supplierBill: {
      create: {
        title: "Nouvelle facture fournisseur",
        success: "Facture ajoutée",
      },
    },

    customerBill: {
      create: {
        title: "Créer une nouvelle facture client",
        success: "Facture client ajoutée.",
      },
    },
    bill: {
      expected: "Facture attendue",
      name: "Libellé",
      providerBillNumber: "N° factu. fournisseur",
      internalNumber: "N° factu. interne",
      internalBillNumber: "N° factu. interne",
      paymentMethod: "Type de paiement",
      bankToDebit: "Compte à débiter",
      paymentType: "Type de paiement",
      dates: "Echéancier de Facturation",
      percentage: "% Projet",
      amounts: "Montants",
      information: "Informations de facturation",
      validated: "Validée",
      billingDate: {
        _: "Date facturation",
        expected: "Date factu. prévue",
      },
      paymentDate: {
        _: "Date paiement",
        expected: "Date pai. prévue",
      },
      emit: {
        bill: "Facturer",
        payment: "Décaisser",
      },
      receive: {
        bill: "Marquer fact. reçue",
        payment: "Marquer réglé",
      },
      chequeNumber: "N° chèque",
      amount: {
        _: "Montant",
        tax: "TVA",
        withoutTax: "Montant HT",
        withoutTaxSuffix: "HT",
        withTax: "Montant TTC",
        withTaxSuffix: "TTC",
        warning:
          "Êtes-vous sûr de vouloir entrer un montant négatif (Les factures fournisseur ne concernant pas un remboursement devraient être entrées en positif)?",
      },
      update: {
        success: "La facture a été modifiée.",
      },
      action: {
        paid: "À encaisser",
        pay: "À décaisser",
        emit: "À facturer",
        receive: "À recevoir",
      },
    },
    billing: {
      atDate: {
        billing: "Facturation",
        paid: "Paiement",
      },
      billType: {
        customer: "Client",
        supplier: "Fournisseur",
      },
      actions: {
        _: "Actions",
        title: "Actions à entreprendre",
      },
      estimated: "Prévisionnel",
      lastYear: "Année n-1",
      billed: "Facturé",
      paid: "Réglé",
      late: "Retard",
      reference: "Référence",
      table: {
        in: "Entrées",
        out: "Sorties",
        total: "Total",
        inPaymentLate: "Retards Encaissement",
        inBillingLate: "Retards Emission",
        outPaymentLate: "Retards Décaissement",
        outBillingLate: "Retards Réception",
      },
    },

    header: {
      welcomeMessage: "Bonjour __name__",
    },

    login: {
      logOutBtn: "Déconnexion",
      logInBtn: "Connexion",
      username: "Identifiant",
      password: "Mot de passe",
    },

    paymentMethods: {
      cheque: "Chèque",
      transfer: "Virement",
      direct_debit: "Prélèvement",
      payment_card: "Carte de crédit",
    },

    configuration: {
      users: {
        title: "Utilisateurs",
        createUserBtn: "Ajouter",
        createUser: {
          title: "Ajouter un utilisateur",
        },
        editUser: {
          title: "Modifier un utilisateur",
        },
      },
      providers: {
        title: "Fournisseurs",
        createProviderBtn: "Ajouter",
        createProvider: {
          title: "Ajouter un fournisseur",
          success: "Fournisseur créé",
        },
        editProvider: {
          title: "Modifier un fournisseur",
          success: "Fournisseur mis à jour",
        },
      },
      products: {
        title: "Produits",
        createProductBtn: "Ajouter",
        createProduct: {
          title: "Ajouter un produit",
        },
        editProduct: {
          title: "Modifier un produit",
        },
      },
      banks: {
        title: "Banque",
        createBtn: "Ajouter",
        create: {
          title: "Ajouter une banque",
          success: "Banque créée",
        },
        edit: {
          title: "Modifier une banque",
          success: "Banque mise à jour",
        },
      },
      costEstimateTemplates: {
        title: "Modèle de Déboursé",
        createBtn: "Ajouter",
        create: {
          title: "Ajouter un modèle",
          success: "Modèle créé",
        },
        edit: {
          title: "Modifier un modèle",
          success: "Modèle mis à jour",
        },
        clone: {
          title: "Dupliquer un modèle",
          success: "Modèle dupliqué",
        },
      },
      constructionDefinition: {
        _: 'Chiffrage',
        roomTemplate: {
          _: 'Modèles de pièces',
          add: 'Ajouter un modèle',
        },
        roomType: {
          _: 'Types de pièces',
        },
      },
    },

    costEstimateTemplate: {
      name: "Nom",

      row: {
        name: "Désignation",
        description: "Description",
        visibilityFormula: "Condition d'affichage",
        supply: "Fourniture",
        labor: "Main d'Oeuvre",
        service: "Fourniture et pose / Prestation",

        supplyQuantityFormula: "Quantité Unitaire",
        supplyUnit: "Unité",
        supplyUnitPrice: "Déboursé Unitaire",

        laborUnitQuantity: "Quantité Unitaire",
        laborUnit: "Unité",
        laborUnitPrice: "Déboursé Unitaire",

        serviceQuantityFormula: "Quantité Unitaire",
        serviceUnit: "Unité",
        serviceUnitPrice: "Déboursé Unitaire",

        create: {
          title: "Ajouter une ligne",
          success: "Ligne créée",
        },
        update: {
          title: "Modifier une ligne",
          success: "Ligne mise à jour",
        },
      },
    },

    costEstimate: {
      row: {
        name: "Désignation",
        description: "Description",

        supply: "Fourniture",
        labor: "Main d'Oeuvre",
        service: "Fourniture et pose / Préstation",

        supplyQuantity: "Quantité Unitaire",
        supplyUnit: "Unité",
        supplyUnitPrice: "Déboursé Unitaire",
        totalSupply: "Déboursé Sec",

        laborUnitQuantity: "Quantité Unitaire",
        laborUnit: "Unité",
        laborUnitPrice: "Déboursé Unitaire",
        totalLabor: "Déboursé Sec",

        serviceQuantity: "Quantité Unitaire",
        serviceUnit: "Unité",
        serviceUnitPrice: "Déboursé Unitaire",
        totalService: "Déboursé Sec",

        total: "Total",

        new: "Nouvelle ligne",
      },
      totalAmountSection: 'Total lot',
      margin: 'Marge',
      total: 'Total déboursé',
      definition: {
        _: 'Définition',
        product: 'Produit',
        price: 'Prix',
      },
    },

    document: {
      title: "Nom",
      type: "Type",
      updatedAt: "Modifié le",

      types: {
        other: "Autre",
        "cadastral-plan": "Plan cadastral",
      },

      createDocument: {
        title: "Ajouter un document",
      },
      editDocument: {
        title: "Modifier un document",
      },
    },

    user: {
      _: 'Utilisateur',
      name: "Nom",
      email: "Email",
      firstName: "Prénom",
      lastName: "Nom",
      username: "Identifiant",
      password: "Mot de passe",
      passwordConfirm: "Confirmer le mot de passe",
    },
    supplier: {
      _: "Fournisseur",
    },
    provider: {
      _: "Fournisseur",
      name: "Nom",
    },
    product: {
      _: 'Produit',
      name: "Nom",
      price: "Prix",
      provider: "Fournisseur",
      size: 'Dimensions',
      category: {
        _: 'Catégorie de produit',
        external: 'Menuiserie extérieure',
        internal: 'Menuiserie intérieure',
        sanitary: 'Sanitaire',
      },
      type: {
        _: 'Type de produit',
        toilet: 'WC',
        shower: 'Douche',
        door: 'Porte',
        window: 'Fenêtre',
        stairs: 'Escalier',
        wainscoting: 'Lambris',
        lining: 'Doublage',
      },
    },
    bank: {
      name: "Nom",
      iban: "IBAN",
      bic: "BIC",
      isDefaultCreditBank: "Banque de crédit par défaut",
      establishmentCode: "Code établissement",
      //sortCode: ''
      accountNumber: "N° compte",
      riceCode: "Code RICE",
      phone: "Téléphone",
      email: "E-Mail",
    },

    land: {
      createLand: {
        title: "Ajouter un terrain",
        success: "Le terrain a été ajouté.",
      },
      createPicture: {
        error: "La photo n'a pas pu être uploadé.",
        success: "La photo a été uploadée.",
      },
      deletePicture: {
        success: "La photo a été supprimée.",
        error: "La photo n'a pas pu être supprimée.",
      },
      update: {
        success: "Le terrain a été modifié.",
      },

      reference: "Référence",
      isPublic: "Visible au public",
      representative: "Interlocuteur GinkGo",
      marketDate: "Date de mise en vente",
      buyer: "Acquéreur",
      surfaceArea: "Superficie",
      price: "Prix",
      residenceType: "Type d'habitation",
      isSitePrepared: "Viabilisation",
      sitePreparationInfo: "Info complémentaires viabilisation",
      landSlope: "Pente du terrain",
      levelFromHighways: "Niv. du terrain par rapport à la voirie",
      neighbourBuildingType: "Constructions voisines",
      nearPointsOfInterest: "Dans un rayon de 500m",
      observations: "Observations",
      worksToQuote: "Travaux à chiffrer",

      otherObservations: "Autres observations",
      otherWorksToQuote: "Autres travaux",

      residenceTypes: {
        housing_scheme: "Lotissement",
        individual: "Individuel",
      },
      landSlopes: {
        small: "Faible",
        medium: "Moyenne",
        hight: "Importante",
      },
      levelsFromHighways: {
        lower: "Plus bas",
        same: "Même niveau",
        higher: "Plus haut",
      },
      neighbourBuildingTypes: {
        recent: "Récent",
        old: "Ancien",
        contemporary: "Contemporain",
        wood: "Bois",
      },
      sitePrepared: "Viabilisé",
      siteNotPrepared: "Non viabilisé",
    },

    room: {
      add: "Ajouter une pièce",
      bedroom: "Chambre",
      showerroom: "Salle d'eau",
      bathroom: "Salle de bain",
      parentSuite: "Suite parentale",
      office: "Bureau",
    },
    equipment: {
      bathroom: {
        _: 'Sanitaire',
        bath: "Baignoire",
        bathtub: "Baignoire",
        italianShower: "Douche à l'italienne",
        shower: "Douche (cabine)",
        toilet: "WC",
        washstand: "Lave-main",
        washBasin: "Lave-main",
        sv: "SV",
        simpleBasin: 'Vasque simple',
        doubleBasin: 'Double vasque',
      },
      heating: {
        _: 'Chauffage',
        electric: 'Électrique',
        granule: 'Poêle à granulé',
        towalRack: 'Sèche serviette',
      },
      external: {
        _: 'Menuiserie extérieure',
        door: 'Porte',
        double_door: 'Porte double battant',
        double_sliding_door: 'Porte coulissante double battant',
        sliding_door: 'Porte coulissante',
        window: 'Fenêtre',
        double_window: 'Fenêtre double battant',
        sliding_window: 'Fenêtre couissante',
        tilt_turn_window: 'Fenêtre oscillo-battante',
        double_tilt_turn_window: 'Fenêtre oscillo-battante double battant',
        fixed_window: 'Fenêtre fixe',
        door_windowed: 'Porte-fenêtre',
        double_door_windowed: 'Porte-fenêtre double battant',
        double_sliding_door_windowed: 'Porte-fenêtre coulissante double battant',
        roller_blind: 'Volet roulants',
      },
      internal: {
        _: 'Menuiserie intérieure',
        door: 'Porte',
        double_door: 'Porte double battant',
        double_sliding_door: 'Porte coulissante double battant',
        sliding_door: 'Porte coulissante',
        stairs: 'Escaliers',
      },
      noEquipments: 'Aucun équipement disponible',
    },

    project: {
      _: "Projet",
      reference: "Référence",
      title: "Intitulé",
      client: "Client",
      budget: "Budget",
      state: "Etat",

      states: {
        in_costing: "En chiffrage",
        signed_contract: "Contrat signé",
        work_start: "Travaux en cours",
      },
      info: {
        title: "Informations",
      },
      customerBill: {
        title: "Facturation Client",
        create: {
          title: "Créer une nouvelle facture client",
          success: "Facture client ajoutée.",
        },
      },
      edit: {
        success: "Projet mis à jour",
      },
      createProject: {
        title: "Ajouter un projet",
      },
    },

    constructionDefinition: {
      add: {
        from: "À partir d'un chiffrage",
        new: "Nouveau chiffrage",
      },
      addComponent: "Ajouter un composant",
      electricity: {
        _: "Électricité",
        tv: "Prise TV",
        ceiling_light: "Plafonnier",
        socket16: "Prise 16A+T",
        phone: "Téléphone",
      },
      internalFurniture: {
        _: "Menuiserie intérieure",
        testIn: "test",
      },
      externalFurniture: {
        _: "Menuiserie extérieure",
        testEx: "testE",
      },
      floorLevel: {
        storey: 'Étage',
        0: "RDC",
        1: "Premier étage",
        2: "Second étage",
        "-1": "Sous-sol",
        total: "Tous niveaux",
        add: "Ajouter un niveau",
        livingArea: "Surface habitable",
        floorboardArea: "Surface plancher",
        area: "Surface brute",
        perimeter: "Périmètre brut",
        loadBearingWall: "Longueur de refends (dalle)",
        partitionWall: "Longueur de cloison",
        room: "Chambre",
        header: "Chevêtre",
      },
      reference: "Référence",
      ipe: {
        _: "Type IPE",
        ground_roofing_without_greening:
          "Construction RDC + toiture étanchée sans végétalisation",
        ground_waterproofing_greening:
          "Construction RDC + toiture tuile ou étanchéité + végétalisation",
        ground_waterproofing_floor_screed:
          "Construction RDC + toiture tuile ou étanchéité végétalisé + chape au sol",
        floor_terrace_without_greening:
          "Construction étage + terrasse étanchée sans végétalisation",
        floor_terrace_greening:
          "Construction étage + terrasse étanchée + végétalisation ou toiture tuiles",
        floor_waterproofing_floor_screed:
          "Construction étage + étanchéité végétalisé ou toiture tuile + chape au sol",
      },
      concreteStudy: {
        _: "Type étude béton",
        standard: "Standard",
        standard_large_ville: "Standard large villa",
        complex: "Complexe",
      },
      networkDistance: "Distance réseaux",
      floorDepth: "Profondeur sol",
      windowSill: "Appui de fenêtre",
      insulation: {
        _: 'Isolation',
        woodFiber240: 'Fibre de bois 240mm',
        wadding240: 'Ouate insufflée 240mm',
        soundReinforced: 'Renforcement acoustique',
      },
      wainscoting: 'Sousface lambris pour débord plancher',
      length: {
        floorBoard: 'Longueur de plancher',
        laminatedWood: 'Lamellé-collé',
      },
      wall: {
        add: 'Ajouter un mur',
        types: {
          cladding: 'Bardage',
          vcladding: 'Bardage vertical',
          coating: 'Enduit',
          "45x95": 'Ossature 45*95',
          "45x120": 'Ossature 45*120',
          "45x145": 'Ossature 45*145',
        },
        osb12: 'OSB 12',
        woodFiber: 'Fibre de bois',
        rod: 'Baguette',
        rentMachinery: 'Location engins',
        scaffolding: 'Echafaudage',
      },
      roofPart: {
        add: 'Ajouter une partie de toit',
        flat: {
          _: 'Plat',
          area: 'Surface étancheité',
          perimeter: "Périmètre étancheité",
          nbOutput80mm: "Nombre sortie EP 80mm",
          nbOutput60mm: "Nombre Trop plein EP 60mm",
          nbOutputStove: "Nombre sortie poele/hotte/primaire",
          withWoolOfWood: 'Laine de bois insufflée',
          withWadding: 'Ouate insufflée',
          WithLengthBig: 'Plancher entre 5 et 6m',
          withVegetatedFloor: 'Plancher végétalisé',
          floorLevelLaminatedWoodLength: 'Longueur de LC',
        },
        slope: {
          areaWithEaves: 'Surface à plat (avec débord)',
          perimeterWithEaves: 'Périmètre extérieur (débord)',
          widthEaves: 'Largeur débord',
          ridgeLength: 'Longueur faîtage',
          widthRoof: 'Largeur toiture',
          hipLength: 'Longueur arêtier',
          nbOutputWoodBurner: 'Nombre conduit poêle',
          nbTransport: 'Transport de charpente',
          spanLength: 'Portée de la charpente',
          isTraditional: 'Charpente traditionelle',
          zincCoating: 'Option couverture zinc',
          woodFiberInsulation: 'Option isolation fibre de bois',
          daysRentMachinery: 'Location engins de levage',
          daysScaffolding: 'Echafaudage sous face',
        },
        slope2sides: 'Charpente - 2 pentes',
        slope4sides: 'Charpente - 4 pentes',
      },
      costEstimateTemplate: 'Modèle de déboursé',
      amount: 'Montant HT',
      state: {
        _: 'État',
        draft: 'Brouillon',
        validated: 'Validé',
        generated: 'Généré',
      },
      floorType: {
        _: 'Type de sol',
        floorboard: 'Parquet',
        tiles: 'Carrelage',
      },
    },
    prospect: {
      _: 'Prospect',
      title: "Prospects",
      information: "Prospect",
      land: {
        _: "Terrain / Existant",
        area: "Superficie souhaitée",
        budget: "Budget (terrain)",
        areaReal: "Superficie",
        information: "Informations",
        truckAccess: "Accès camion",
        surveyorPlanPresent: "Plan géomètre",
        surveyorPlanReference: "Référence",
        hasLocalUrbanizationPlan: "Zone PLU",
        floodPreventionPlanReference: "PPRI",
        sanitation: {
          _: "Assainissement",
          sewer: "TAE",
          not_grouped: "Non collectif",
        },
      },
      constructionDefinitions: "Chiffrages",
      land_only: "Terrain",
      existing_land: "Existant",
      update: {
        success: "Le prospect a été modifié.",
        error: "Le prospect n'a pas pu être modifié.",
        title: "Modification",
      },
      creation: {
        success: "Le prospect a été créé.",
        error: "Le prospect n'a pas pu être créé.",
        title: "Créer un prospect",
      },
      existing: {
        existing: "Existant",
        materialType: "Type matériau",
        roofType: "Type toit",
        renovationIsPlanned: "Rénovation prévue",
        renovationBudget: "Budget rénovation",
        surfaceArea: 'Surface habitable existante',
      },

      project: {
        _: "Projet",
        budget: "Budget",
        finishing: {
          _: "Finition",
          ready: "Clés en main",
          to_decorate: "PAD",
          to_finish: "PAF",
        },
        credit: {
          withCredit: "Prêt conventionné",
          date: "Date butoire prêt",
          accepted: "Accord banque",
        },
        withSecondFloor: "Avec étage",
        surfaceArea: "Surface habitable",
        livingSpaceArea: "Espace de vie",
        floor: {
          _: "Sol",
          parquet: "Parquet",
          tiling: "Carrelage",
        },
        roof: {
          _: "Toit",
          slope: "Pente",
          mixed: "Mixte",
          flat: "Plat",
        },
        facadeType: "Facade (revêtement)",
        room: "Pièces",
        bathroomEquipments: "Salle de bains - Equipements",
        garageSurface: 'Surface garage',
        heating: 'Type de chauffage',
      },

      firstname: "Prénom",
      lastname: "Nom",
      phone: "Téléphone",
      email: "Mail",
      note_add: "Ajouter des notes",
      address: "Adresse",
      type: {
        extension: "Extension",
        with_land: "Avec terrain",
        without_land: "Sans terrain",
        elevation: "Surélévation",
      },
      add_sector: "Ajouter un secteur de recherche.",
      add_sector_help: "Pour ajouter un secteur, modifiez le prospect.",
      sectors: "Secteur de recherche",
      sector: "Secteur __num__",
    },
    address: {
      address: "Adresse",
      city: "Ville",
      code: "Code postal",
    },
  },
  validation: {
    default: "Erreur de validation sur le champ %s",
    required: "%s est requis",
    enum: "%s doit être l'un de %s",
    whitespace: "%s ne peut pas être vide",
    date: {
      format: "%s date %s is invalid for format %s",
      parse: "%s date could not be parsed, %s is invalid ",
      invalid: "%s date %s is invalid",
    },
    types: {
      string: "%s n'est pas un %s",
      method: "%s n'est pas un %s (fonction)",
      array: "%s n'est pas un %s",
      object: "%s is not an %s",
      number: "%s n'est pas un %s",
      date: "%s n'est pas un %s",
      boolean: "%s n'est pas un %s",
      integer: "%s n'est pas un %s",
      float: "%s n'est pas un %s",
      regexp: "%s n'est pas un %s valide",
      email: "%s n'est pas un %s valide",
      url: "%s n'est pas un %s valide",
      hex: "%s n'est pas un %s valide",
    },
    string: {
      len: "%s doit faire éxactement %s caractères",
      min: "%s doit faire au moins %s caractères",
      max: "%s ne peut pas faire plus de %s caractères",
      range: "%s doit faire entre %s et %s caractères",
    },
    number: {
      len: "%s doit être égal à %s",
      min: "%s ne peut pas être inférieur à %s",
      max: "%s ne peut pas être supérieur à %s",
      range: "%s doit être compris entre %s et %s",
    },
    array: {
      len: "%s must be exactly %s in length",
      min: "%s cannot be less than %s in length",
      max: "%s cannot be greater than %s in length",
      range: "%s must be between %s and %s in length",
    },
    pattern: {
      mismatch: "La valeur de %s (%s) ne correspond pas au format %s",
    },
  },
  // Ant Design
  Table: {
    filterTitle: "Rechercher...",
    filterConfirm: "OK",
    filterReset: "Réinitialiser",
    emptyText: "Aucune donnée",
  },
  Modal: {
    okText: "OK",
    cancelText: "Annuler",
    justOkText: "OK",
  },
  Popconfirm: {
    okText: "OK",
    cancelText: "Annuler",
  },
  Transfer: {
    notFoundContent: "Pas de résultat",
    searchPlaceholder: "Recherche",
    itemUnit: "élément",
    itemsUnit: "éléments",
  },
  Select: {
    notFoundContent: "Pas de résultat",
  },
  Pagination: {
    // Options
    items_per_page: "/ page",
    jump_to: "Allez à",
    page: "",

    // Pagination
    prev_page: "Page Précédente",
    next_page: "Page Suivante",
    prev_5: "5 Pages Précédentes",
    next_5: "5 Pages Suivantes",
    prev_3: "3 Pages Précédentes",
    next_3: "3 Pages Suivantes",
  },
  TimePicker: {
    placeholder: "",
  },
  DatePicker: {
    lang: {
      today: "Aujourd'hui",
      now: "Maintenant",
      backToToday: "Retour à aujourdh'hui",
      ok: "Ok",
      clear: "Vider",
      month: "Mois",
      year: "Année",
      timeSelect: "Sélectionner l'heure",
      dateSelect: "Sélectionner une date",
      monthSelect: "Sélectionner un mois",
      yearSelect: "Sélectionner une année",
      decadeSelect: "Sélectionner une décénie",
      yearFormat: "YYYY",
      dateFormat: "MM/DD/YYYY",
      dayFormat: "D",
      dateTimeFormat: "MM/DD/YYYY HH:mm:ss",
      monthFormat: "MMMM",
      monthBeforeYear: true,
      previousMonth: "Mois précédent (PageUp)",
      nextMonth: "Mois suivant (PageDown)",
      previousYear: "Année précédente (Control + left)",
      nextYear: "Année suivante (Control + right)",
      previousDecade: "Décennie précédente",
      nextDecade: "Décennie suivante",
      previousCentury: "Siècle précédent",
      nextCentury: "Siècle suivant",

      placeholder: "Sélectionnez une date",
      rangePlaceholder: ["Date de début", "Date de fin"],
    },
  },
};
