import React from "react";
import Locale from "locale/LocaleFactory";

export default class FormItemBase extends React.Component {
  hasErrors() {
    const { getFieldsError } = this.props.form;
    const fieldsError = getFieldsError();
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  }

  getValidateStatus(name) {
    const { form } = this.props;
    const { getFieldError, isFieldTouched } = form;

    const hasError = !!getFieldError(name);
    if (hasError) {
      return "error";
    }
    if (isFieldTouched(name)) {
      return "success";
    }
    return null;
  }

  getHelp(name, translation) {
    const error = this.props.form.getFieldError(name);
    if (!error || !translation) {
      return error;
    }
    return error.map(item => item.replace(name, translation));
  }

  getFieldDecorator(id, options) {
    return this.props.form.getFieldDecorator(id, options);
  }

  getRules(baseRules) {
    let allRules = [];

    if (baseRules) {
      allRules = allRules.concat(baseRules);
    }

    const { required, type, rules } = this.props;

    if (required) {
      allRules.push({
        required: true,
        message: Locale.trans("error.required")
      });
    }

    if (type === "email") {
      allRules.push({
        type: "email",
        message: Locale.trans("error.invalidEmail")
      });
    }

    if (rules) {
      allRules = allRules.concat(rules);
    }

    return allRules;
  }
}
