import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

import { notification } from "antd";

import LoginForm from "views/LoginForm";
import Panel from "components/Panel";

import "style/views/Login.scss";
import logo from "images/logo.png";

import { AuthConsumer } from "contexts/AuthContext";

const CREATE_AUTH_TOKEN = gql`
  mutation createAuthToken($input: AuthTokenInput!) {
    createAuthToken(input: $input) {
      value
      createdAt
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export default class Login extends React.Component {
  state = {
    fields: {}
  };

  handleCreateAuthTokenError = err => {
    notification.error({ message: err.message });
  };

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }));
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    return (
      <AuthConsumer>
        {({ isAuth, updateAuthToken }) =>
          isAuth ? <Redirect to={from} /> : this.renderContent(updateAuthToken)
        }
      </AuthConsumer>
    );
  }

  renderContent(updateAuthToken) {
    return (
      <div className="login-view">
        <Panel>
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <Mutation
            mutation={CREATE_AUTH_TOKEN}
            onCompleted={data => updateAuthToken(data.createAuthToken)}
            onError={this.handleCreateAuthTokenError}
          >
            {(createAuthToken, { loading }) => (
              <LoginForm
                onChange={this.handleFormChange}
                fields={this.state.fields}
                onLogin={(username, password) =>
                  createAuthToken({
                    variables: {
                      input: {
                        username,
                        password
                      }
                    }
                  })
                }
                loading={loading}
              />
            )}
          </Mutation>
        </Panel>
      </div>
    );
  }
}
