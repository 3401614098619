import React from "react";

import { updateAuthToken } from "services/ApolloClient";
import ToastService from 'services/ToastService';
import Locale from 'locale/LocaleFactory';

let AUTH_TOKEN = null;
let listener = null;

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  constructor(props) {
    super(props);

    const authTokenStr = localStorage.getItem("auth-token");
    const authToken = authTokenStr ? JSON.parse(authTokenStr) : null;

    if (authToken) {
      updateAuthToken(authToken.value);
    }
  
    AUTH_TOKEN = authToken;
    listener = this.updateAuthToken;

    this.state = {
      isAuth: !!authToken,
      authToken,
      updateAuthToken: this.updateAuthToken
    };
  }

  updateAuthToken = authToken => {
    if (authToken) {
      localStorage.setItem("auth-token", JSON.stringify(authToken));
      updateAuthToken(authToken.value);
    } else {
      localStorage.removeItem("auth-token");
    }

    AUTH_TOKEN = authToken;
    this.setState({
      isAuth: !!authToken,
      authToken
    });
  };

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

/**
 * We use this function to be able to access
 * the auth token from outside React components.
 */
function getAuthToken() {
  return AUTH_TOKEN;
}

function getUrlFormatedToken() {
  const token = getAuthToken();
  return token ? token.value.replace(/\+/g, '%2B') : '';
}

function logout() {
    if (listener != null) {
        listener(null);
    }
    ToastService.error(Locale.trans('logged_out'), Locale.trans('logged_out_description'));
}

export { AuthProvider, AuthConsumer, getAuthToken, getUrlFormatedToken, logout };
